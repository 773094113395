export const moo = [
  'Blush',
  'Buddy',
  'Crypto',
  'Diamond',
  'Earring',
  'Flower',
  'Freckles',
  'GM Brand',
  'Tattoo',
  'Wrist',
  'Egg',
  'Crumbs',
];
