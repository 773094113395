import { isAddress } from 'ethers/lib/utils';
import { useState } from 'react';
import { CollectionType, ProviderProps } from '../util/types';
import useAddressToIds from '../util/useAddressToIds';
import { EntryContext } from './EntryContext';
import { useFilterContext } from './FilterContext';

export const EntryProvider = ({ children, metadata }: ProviderProps) => {
  const { moos, keekus } = metadata;

  const { filters, count, search: search_ } = useFilterContext();
  const [type, setType] = useState<CollectionType>('gmoo');

  const displayName = type === 'gmoo' ? 'Moo' : 'Keek';

  const totalCount = type === 'gmoo' ? 333 : 3333;

  const search = search_.toLowerCase();

  const ids = useAddressToIds(search, type);

  const searches = search.split(' ').map((s) => s.toLowerCase());

  const collection = type === 'gmoo' ? moos : type === 'keek' ? keekus : [];
  const entries = collection
    .filter(
      ({ id }) =>
        search.length === 0 ||
        searches.every((s) => isNaN(Number(s))) ||
        // Check every searches is number, otherwise '29 ab' will still return 29 and confuse users
        (searches.every((s) => !isNaN(Number(s))) && searches.some((s) => parseInt(s) === id)) ||
        // Address for some reason is a number, so exclude it from id search
        isAddress(search)
    )
    .filter(({ id }) => ids.length === 0 || ids.includes(id))
    .filter(({ attributes }) =>
      Object.entries(filters).every(([type, values]) => {
        // removing filters can result in an empty set
        if (values.size === 0) {
          return true;
        }

        const trait = attributes.find((attribute) => attribute.trait_type === type);

        return trait ? values.has(trait.value) : false;
      })
    )
    .filter(
      ({ attributes }) =>
        // Search is an ENS query
        (search.includes('.eth') && ids.length > 0) ||
        // Search is an address
        (isAddress(search.toLowerCase()) && ids.length > 0) ||
        // Search is empty
        search.length === 0 ||
        // Search is id
        !searches.every((s) => isNaN(Number(s))) ||
        // Attribute value is string and contains search
        searches.every((s) =>
          attributes.some(
            (attribute) =>
              typeof attribute.value === 'string' && attribute.value.toLowerCase().includes(s)
          )
        ) ||
        searches.every((s) =>
          // Trait type contains search and value is not none
          attributes.some(
            (attribute) =>
              attribute.trait_type?.toLowerCase().includes(s) && attribute.value !== 'None'
          )
        )
    );

  return (
    <EntryContext.Provider
      value={{
        metadata: entries,
        paginated: entries.slice(0, count),
        type,
        setType,
        displayName,
        totalCount,
      }}
    >
      {children}
    </EntryContext.Provider>
  );
};
